export const env = {
    BASE_URL: 'https://api-staging.babypage.com/',
    DEBUG: false,
    FACEBOOK_API: {
        id: '132994857336675',
        version: 'v3.2'
    },    
    SESSION_TIMEOUT: {
        value: 2,
        unit: 'hours'
    },
    FACEBOOK_PIXEL: {
        name: 'Pixel de Prueba',
        id: '126914508124403'
    },
    GOOGLE_ANALYTICS: {
        name: 'Babypage Prueba',
        id: 'UA-114428285-1'
    },
    GOOGLE_PHOTOS: {
        name: 'Babypage Prueba', 
        id: '636675358823-jbnh9fasfhr6rlf8v56aphu7bndnip6q.apps.googleusercontent.com',
        apiKey: 'AIzaSyDvwfTmK42rTREKgdMu0X7maAkyiC3OM1U'
    },
    CLIENT_CREDENTIALS: {
        key: '98c02eed-8df9-4751-8387-4e241ed02a63',
    },
    GENERAL: {
        token_device: '',
        lang: 'en',
        operative_system: 'web',
        paginator_limit: 10,
        purchase_paginator_limit: 5,
        default_discount: 'one_year_subscription_discount',
        accept: 'application/x.babypage.v1.0.0+json',
        app_env: 'staging'
    },
    STICKERS: {
        max_stickers_per_bp: 10 
    },
    BABYPAGES: {
        max_babypages_per_free_user: 10 
    },
    AMPLITUDE: {
        apiKey: 'e822c86547c4c0b2858baa305a05c11d' 
    },
    PAYPAL: {
        client: {
            sandbox: 'AVMxMLV_0Kng1fc-WECeQHWkUF-mhcZJlkk7OvaZ7-hii6GFhv6BA52mMCFPo1TbbT7YX4FNS8h3DMAu',
            production: ''
        },
        merchantId: 'EVUSSQMP3UUQ8',
        mode: 'sandbox',
        commit: true,
        locale: 'en_US',
        style: {
            label: 'checkout', // checkout | credit | pay | buynow | generic
            size: 'responsive',    // small | medium | large | responsive
            shape: 'rect',     // pill | rect
            color: 'blue',      // gold | blue | silver | black
            tagline: false    
        },
        buttons: {
            monthly: 'UGSVC4K6ASHYY',
            three_month: 'NV69VGU6YK8KU',
            one_year: '3RMJ2C8VJRLPL'
        }
    }
};

export default env;
